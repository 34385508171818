import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Tutorial from "./components/Tutorial";
import Plans from "./components/Plans";
import Footer from "./components/Footer";
import Login from "./components/Login";
import CreateAcc from "./components/SignUp";
import NewNav from "./components/NewNav";
import FeedSearch from "./components/FeedSearch";
import FeedCard from "./components/FeedCard";
import Completion from "./components/Completion";
import CreatePost from "./components/CreatePost";
import ViewOffer from "./components/ViewOffer";
import Chat from "./components/Chat";
import Home from "./components/Home";
import Marketplace from "./components/Marketplace";
import MakeOffer from "./components/MakeOffer";
import Notifications from "./components/Notifications";
import NotificationBox from "./components/NotificationBox";
import AdsList from "./components/AdsList";
import Account from "./components/Account";
import SignUp from "./components/SignUp";
import ReportForm from "./components/ReportForm";
import Faq from "./components/Faq";
import ResetPassword from "./components/ResetPassword";
import ForgotPassword from "./components/ForgotPassword";
import LoggedIn from "./components/LoggedIn";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import { PostAdd } from "@material-ui/icons";
import SplashScreen from "./components/SplashScreen"
import LoginOrSignup from "./components/LoginOrSignup"
import FINDTRADEHOME from "./components/FINDTRADEHOME";
import Tac from "./components/TAC";

function App() {
  const navigate = useNavigate();
  // Only set initial showSplash to true for mobile screens
  const [showSplash, setShowSplash] = useState(window.innerWidth <= 768);
  const [initialNavigationDone, setInitialNavigationDone] = useState(false);

  useEffect(() => {
    if (!initialNavigationDone) {
      if (showSplash) {
        // If splash is being shown (i.e., on mobile screens)
        setTimeout(() => {
          setShowSplash(false);
          // navigate("/loginorsignup");
          navigate("/");
          //navigate("/login");
          setInitialNavigationDone(true);
        }, 2000);
      } else {
        // If on larger screens, directly navigate to home
        navigate("/");
        setInitialNavigationDone(true);
      }
    }
  }, [navigate, initialNavigationDone, showSplash]);

  // Tawk.to chatbot integration
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = "https://embed.tawk.to/651ed8a76fcfe87d54b6da0e/1hc07sk14";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  if (showSplash) {
    return <SplashScreen />;
  }
  return (
    <div>
      <UserAuthContextProvider>
        <Routes>
          {/* <Route path="/" element={<SplashScreen />} /> */}
          <Route path="/findtradehome" element={<FINDTRADEHOME />} />
          <Route path="/loginorsignup" element={<LoginOrSignup />} />
          <Route path="/" element={<Home />} />
          <Route path="/viewoffer" element={<ViewOffer />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/tac" element={<Tac />} />
          <Route
            path="/loggedin"
            element={
              <ProtectedRoute>
                <LoggedIn />
              </ProtectedRoute>
            }
          />
          <Route
            path="/marketplace"
            element={
              <ProtectedRoute>
                <Marketplace />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/postad"
            element={
              <ProtectedRoute>
                <CreatePost />
              </ProtectedRoute>
            }
          />
          <Route
            path="/adslist"
            element={
              <ProtectedRoute>
                <AdsList />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account"
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          />
          <Route
            path="/makeoffer"
            element={
              <ProtectedRoute>
                <MakeOffer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reportform"
            element={
              <ProtectedRoute>
                <ReportForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/faq"
            element={
              <ProtectedRoute>
                <Faq />
              </ProtectedRoute>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <ForgotPassword />
            }
          />
          <Route
            path="/resetpassword"
            element={
              <ProtectedRoute>
                <ResetPassword />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAuthContextProvider>
    </div>
  );
}

export default App;
