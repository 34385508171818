import React, { useState, useEffect } from "react";
import "../Chat.css";
import NewNav from "./NewNav";
import Sidebar from "./Sidebar";
import Inbox from "./Inbox";
import Footer from "./Footer";
function Chat() {
  const [roomID, setRoomID] = useState("");

  const handleClick = (id) => {
    setRoomID(id);
  };

  return (
    <div>
      <NewNav />
      <div className="chat bg-white mb-24">
        <div className="chat_body bg-white ">
          <Sidebar handleClick={handleClick} />
          {roomID ? <Inbox key={roomID} room={roomID} /> : null}
        </div>
      </div>
    </div>
  );
}

export default Chat;
