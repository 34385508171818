import React, { useState, useEffect } from "react";
import NewNav from "./NewNav";
import FeedCard from "./FeedCard";
import FeedSearch from "./FeedSearch";
import Footer from "./Footer";
import Filter from "./Filter";
import Loading from "./Loading";
import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";
//import TourModal from './TourModal';
import { useUserAuth } from "../context/UserAuthContext";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { MdCreate } from 'react-icons/md';

const Marketplace = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [adsList, setAdsList] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [filteredAdsList, setFilteredAdsList] = useState({});
  const navigate = useNavigate();

  const goToPostAd = () => {
    navigate('/postad');
  };

  // const { user, updateUser } = useUserAuth();
  // const [isTourOpen, setIsTourOpen] = useState(false);
  // const [tourStep, setTourStep] = useState(0);

  const getAds = async () => {
    setIsLoading(true);
    try {
      const q = query(collection(db, "posts"));
      const querySnapshot = await getDocs(q);
      const temp = {};
      querySnapshot.forEach((doc) => {
        temp[doc.id] = doc.data();
      });
      setAdsList({ ...temp });
      setFilteredAdsList({ ...temp });
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const handleSearchTermChange = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const handleTypes = (list) => {
    setSelectedTypes(list);
  };

  const handleCategories = (list) => {
    setSelectedCategories(list);
  };

  // const updateUserTourStatus = async (userId) => {
  //   try {
  //     const userDocRef = doc(db, "users", userId);
  //     await updateDoc(userDocRef, {
  //       hasTakenTour: true,
  //     });
  //     // Update the local user state
  //     updateUser({ hasTakenTour: true });
  //   } catch (error) {
  //     console.error("Error updating user:", error);
  //   }
  // };


  useEffect(() => {
    getAds();
  }, []);

  useEffect(() => {
    const newList = Object.values(adsList)
      .filter(
        (ad) =>
          !searchTerm ||
          ad.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter(
        (ad) => selectedTypes.length === 0 || selectedTypes.includes(ad.type)
      )
      .filter(
        (ad) =>
          selectedCategories.length === 0 ||
          selectedCategories.includes(ad.category)
      );
    setFilteredAdsList(newList);
  }, [searchTerm, selectedTypes, selectedCategories]);

  // useEffect(() => {
  //   if (user && !user.hasTakenTour) {
  //     setIsTourOpen(true);
  //   }
  // }, [user]);

  // // Add this useEffect hook in Marketplace.js
  // useEffect(() => {
  //   if (user) {
  //     // Add logic to fetch user's hasTakenTour field from Firestore
  //     // and set isTourOpen based on that
  //     const fetchUserTourStatus = async () => {
  //       const userDocRef = doc(db, "users", user.uid);
  //       const userDocSnap = await getDoc(userDocRef);

  //       // Assuming your user's document has a field `hasTakenTour`
  //       if (userDocSnap.exists()) {
  //         const userData = userDocSnap.data();
  //         // Only show the tour if `hasTakenTour` is false
  //         setIsTourOpen(!userData.hasTakenTour);
  //       } else {
  //         console.log("No such document!");
  //       }
  //     };

  //     fetchUserTourStatus();
  //   }
  // }, [user]);


  return (
    <>
      {/* {isTourOpen && (
        <TourModal
          step={tourStep}
          onSkip={() => {
            setIsTourOpen(false);
            
            updateUserTourStatus(user.uid);
          }}
          onNext={() => {
            if (tourStep < 8) { 
              setTourStep(tourStep + 1);
            } else {
              setIsTourOpen(false);
              
              updateUserTourStatus(user.uid);
            }
          }}
        />
      )} */}
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />
          <div className="flex mb-10 items-center justify-center h-48 bg-gradient-to-l to-green-800 from-green-700 shadow-xl">
            <h1 className="text-5xl font-bold text-white dark:text-white md:text-7xl sm:text-6xl lg:text-5xl">
              <span className="text-white bg-clip-text">Marketplace</span>
            </h1>
          </div>


          <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row justify-between mx-10 mb-20 sm:mb-0">
            <FeedSearch onSearchTermChange={handleSearchTermChange} />
            <div className="flex flex-col items-end">
              <Filter updateTypes={handleTypes} updateCategories={handleCategories} />
              {/* Button with the create post icon */}
              <button onClick={goToPostAd} className="text-white bg-green-500 rounded-full p-2 hover:bg-green-800 transition ease-in duration-200 mt-20">
                <MdCreate size={30} /> {/* Adjust size as needed */}
              </button>
            </div>
          </div>


          {/* <div className="block sm:block md:hidden lg:hidden">
            <div className="mx-10">
              <Filter
                updateTypes={handleTypes}
                updateCategories={handleCategories}
              />
            </div>
            <div className="mx-10 mt-4 mb-2">
              <FeedSearch onSearchTermChange={handleSearchTermChange} />
            </div>
          </div> */}

          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mx-10">
            {Object.keys(filteredAdsList).map((key) => {
              return <FeedCard key={key} data={filteredAdsList[key]} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Marketplace;