import React from 'react';
import { useNavigate } from 'react-router-dom';
import { InView } from 'react-intersection-observer';

const MarketplaceBanner = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="bg-black text-white flex justify-between items-center">
                <div className="max-w-[800px] mt-[96px] w-full pb-20 mx-auto text-center flex flex-col">
                    <InView triggerOnce>
                        {({ inView, ref }) => (
                            <>
                                <h1 ref={ref} className={`md:text-7xl sm:text-6xl text-4xl font-bold md:py-2 ${inView ? 'animate-fadeIn' : ''}`}>The Ultimate Marketplace</h1>
                                <div className="flex justify-center items-center mt-2">
                                    <p ref={ref} className={`md:text-3xl sm:text-2xl text-md font-bold mt-2 mb-4 text-gray-400 ${inView ? 'animate-fadeIn' : ''}`}>Your one-stop money-free shop</p>
                                </div>
                            </>
                        )}
                    </InView>
                    <button onClick={() => { navigate('/login'); }} className="bg-[#68cda6] w-[180px] rounded-md font-medium text-md mt-4 mb-2 mx-auto py-2 text-black hover:scale-105 duration-300">Visit Marketplace</button>
                </div>
            </div>
        </>
    );
};

export default MarketplaceBanner;