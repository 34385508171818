import React, { useState } from "react";
import NewNav from "./NewNav";
import { Input } from "@material-tailwind/react";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loading from "./Loading";
import { useUserAuth } from "../context/UserAuthContext";
import ReportSentModal from "./ReportSentModal"; // Import the ReportSentModal component

const ReportForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [showReportSentModal, setShowReportSentModal] = useState(false); // Add state to control the visibility of ReportSentModal

  const { user } = useUserAuth();

  const openReportSentModal = () => setShowReportSentModal(true); // Open the ReportSentModal
  const closeReportSentModal = () => setShowReportSentModal(false); // Close the ReportSentModal

  const addReport = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await addDoc(collection(db, "reports"), {
        user: user.email,
        title,
        details,
      });
      setTitle("");
      setDetails("");
      setIsLoading(false);
      openReportSentModal(); // Open the ReportSentModal here
    } catch (error) {
      setErr(error.message);
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />
          <form onSubmit={addReport}>
            <div className="grid place-items-center mt-20">
              <div className="max-w-sm p-6 grid place-items-center bg-white border border-gray-300 rounded-lg shadow-md h-full">
                <h1 className="text-3xl font-bold text-green-900 mb-5">
                  Report an issue
                </h1>

                {err && (
                  <div className="shadow-lg rounded-lg bg-red-900 px-5 py-2 mb-5">
                    <p className="block font-semibold text-center text-white">
                      {err}
                    </p>
                  </div>
                )}

                {success && (
                  <div className="shadow-lg rounded-lg bg-green-900 px-5 py-2 mb-5">
                    <p className="block font-semibold text-center text-white">
                      {success}
                    </p>
                  </div>
                )}

                <div className="w-60 mb-5">
                  <Input
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    value={title}
                    placeholder="Problem Title"
                    className="p-2 mt-5"
                  />
                </div>
                <div className="w-72 mb-4 mt-8">
                  <textarea
                    className="p-10 w-full mt-5 mb-0 h-40 resize-none rounded shadow"
                    onChange={(e) => {
                      setDetails(e.target.value);
                    }}
                    value={details}
                    placeholder="Details of the problem"
                  />
                </div>
                <div className="relative">
                  <button
                    type="submit"
                    className="px-4 py-2 mt-14 text-sm text-green-100 bg-green-900 hover:bg-green-500 rounded shadow"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <ReportSentModal showModal={showReportSentModal} closeModal={closeReportSentModal} /> {/* Add the ReportSentModal here */}

        </div>
      )}
    </div>
  );
};

export default ReportForm;
