import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import TextWithLineBreaks from "./TextWithLineBreaks";

const FeedCard = (props) => {
  const navigate = useNavigate();
  const { user } = useUserAuth();

  return (
    <div className="w-full bg-white rounded-lg shadow-md shadow-green-100 hover:shadow-green-300 lg:flex lg:max-w-sm lg:max-h-sm mb-20 hover:scale-105 duration-300 p-2 mx-4 flex flex-col">
      <img
        className="object-cover w-full lg:w-full lg:h-full rounded-lg shadow-lg"
        src={props.data.img}
      />
      <div className="px-2 py-4 flex-grow">
        <h4 className="text-2xl py-2 font-bold text-green-900 mb-2 mt-2">
          {props.data.name}
        </h4>
        <p className="mb-2 py-1 leading-normal font-regular overflow-hidden whitespace-normal max-h-[5rem]">
          <TextWithLineBreaks text={props.data.details} />
        </p>
        <div className="flex">
          <p className="font-medium mr-2">Type: </p>
          <p>
            {props.data.type.charAt(0).toUpperCase() + props.data.type.slice(1)}
          </p>
        </div>
        <div className="flex">
          <p className="font-medium mr-2 mb-4">Category: </p>
          <p>
            {props.data.category
              .split("_")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          </p>
        </div>
      </div>
      {!(props.data.fromEmail == user.email) ? (
        <div className="mb-2">
          <button
            onClick={() => {
              navigate("/makeoffer", { state: { data: props.data } });
            }}
            className="px-4 py-2 text-sm text-green-100 bg-green-900 hover:bg-green-600 rounded shadow"
          >
            Make an offer
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default FeedCard;
