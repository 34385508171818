import React from 'react';
import logo from '../images/SSLogo.png';
import './SplashScreen.css';

const SplashScreen = () => {
    return (
        <div className="splash-screen relative flex items-center justify-center h-screen bg-black">
            <div className="circle absolute -top-20 -left-20 w-80 h-80 bg-gray-500 rounded-full opacity-30 overflow-hidden"></div>
            <img src={logo} alt="App Logo" className="logo" />
        </div>
    );
};

export default SplashScreen;
