export const choices = {
  product: {
    "Electronics": "electronic_devices",
    Furniture: "furniture",
    "Home & Kitchen": "home_kitchen",
    "Decor": "decor",
    "Clothing": "clothing",
    "Beauty & Personal Care": "beauty",
    "Office Supplies": "office",
    "Automotive Accessories & Parts": "automotive",
    "Education": "education",
    "Baby": "baby",
    "Pet Supplies": "pet",
    "Toys & Games": "toys",
    "Sports": "sports",
    "Other": "other",
  },
  service: {
    "Technology": "technology",
    "Electric": "Electric",
    "Education": "Education",
    "Cleaning": "cleaning",
    Plumbing: "plumbing",
    "Financial": "financial",
    "Health": "health",
    "Transport": "transport",
    "Hospitality": "hospitality",
    "Beauty & Grooming": "beauty_grooming",
    "Other": "other",


  },
};





