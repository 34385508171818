import { Link } from 'react-router-dom';
import LogoWalt3 from '../images/logoWalt3.png'
import {FaChevronLeft} from 'react-icons/fa';
const TAC = () => {
    return ( 
        <>
            <div>
            <img src={LogoWalt3} className="w-35 h-20 ml-2 mt-4"/>
                <div className="flex flex-1 flex-col justify-center items-start bg-green-900 py-8 lg:py-20">
                
                    <h2 className="m-auto text-3xl font-bold text-white dark:text-white md:text-6xl sm:text-4xl lg:text-6xl">Terms & Conditions</h2>
                </div>
                <div className=' mt-4 lg:mt-8 p-2 mb-2'>
                    <Link to={"/signup"}><FaChevronLeft className="text-3xl lg:text-4xl hover:text-green-700 text-green-900"/></Link>
                </div>
                <div className="mx-6 lg:mx-14 text-justify">
                    <h4 className="text-lg">Welcome to Jackpt, the premier platform for trading products and services. By creating an account and using our platform, you agree to the following terms and conditions. Please read them carefully.</h4>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">1. Acceptance of Terms</h4>
                        <p>By signing up for Jackpt, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">2. Prohibited Items and Services</h4>
                        <p>Trading of illegal, stolen, or counterfeit items is strictly prohibited. This includes, but is not limited to, drugs, firearms, and any other items that are prohibited by law.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">3. Conduct and Behavior Users are expected to:</h4>
                        <p>Avoid any form of cyberbullying or harassment.</p>
                        <p>Refrain from using profanity, indecent language, or any form of hate speech.</p>
                        <p>Be honest and fair in the valuation of products or services.</p>
                        <p>Communicate with respect and transparency with other users.</p>
                        <p>Fraud and Misrepresentation</p>
                        <p>Any attempt to defraud other users or misrepresent the value, condition, or authenticity of items or services is strictly prohibited and</p>
                        <p>may result in account suspension or termination.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">4. Information Misuse</h4>
                        <p>Users are prohibited from misusing personal information obtained from other users. This includes, but is not limited to, unauthorized sharing, selling, or any form of misuse.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">5. Subscription Fees</h4>
                        <p>Access to the Jackpt marketplace requires a subscription fee. This fee is non-refundable and subject to change.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">6. Trade Agreement</h4>
                        <p>All trade agreements are between users. Jackpt is not responsible for ensuring that trades are completed, nor are we liable for any disputes arising from trades.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">7. Trade Cancellation</h4>
                        <p>If both parties cannot come to an agreement, they have the option to cancel the trade. Jackpt is not responsible for any losses or damages resulting from canceled trades.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">8. Account Termination</h4>
                        <p>Jackpt reserves the right to suspend or terminate accounts that violate these terms and conditions, without notice.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">9. Limitation of Liability</h4>
                        <p>Jackpt is not responsible for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our platform.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">10. Dispute Resolution</h4>
                        <p>Any disputes arising from the use of Jackpt will be resolved through mediation. If mediation is unsuccessful, disputes will be settled through binding arbitration.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">11. Amendments</h4>
                        <p>Jackpt reserves the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the platform constitutes acceptance of the updated terms.</p>
                    </p>
                    <p className="text-lg py-2">
                        <h4 className="font-bold">12. Governing Law</h4>
                        <p>These terms and conditions are governed by the laws of Bangladesh. Any legal actions related to Jackpt must be filed in the jurisdiction of Bangladesh.</p>
                    </p>
                    <p className="text-lg py-2">
                        <p>By clicking "Accept," you agree to these terms and conditions. If you do not agree, please do not create an account or use the Jackpt platform.</p>
                    </p>
                </div>
            </div>
        </>
     );
}
 
export default TAC;