import React, {useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import logoW from '../images/logoW.png'
import logoWalt from '../images/logoWalt.png'
import logoWalt2 from '../images/logoWalt2.png'
import logoWalt3 from '../images/logoWalt3.png'
import { useUserAuth } from "../context/UserAuthContext";
// for translate
import {
    I18nextProvider,
    initReactI18next,
    useTranslation,
  } from "react-i18next";
  import i18n from "i18next";
  import enTranslation from "./../translate/en.json";
  import bnTranslation from "./../translate/bn.json";
  // import { LiaToggleOnSolid, LiaToggleOffSolid } from "react-icons/lia";
  import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
  
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      bn: {
        translation: bnTranslation,
      },
    },
    lng: localStorage.getItem("language") || "en",
    interpolation: {
      escapeValue: false,
    },
  });

const Navbar = () => {
    const { logout } = useUserAuth();

    const [nav, setNav] = useState(false)
    const navigate = useNavigate();

    const handleNav = () => {
        setNav(!nav)
    }

    const handleLogout = async () => {
        try {
        await logout();
        if (window.innerWidth <= 768) {
          navigate("/");
        } else {
          navigate("/");
        }
        } catch (err) {
        console.log(err.message);
        }
    };
    const handleHomeClick = () => {
        if (window.innerWidth <= 768) {
          // Checks if screen width is less than or equal to 768 pixels
          navigate("/");
        } else {
          navigate("/");
        }
      };
    //   for translation
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "en"
  );

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "bn" : "en";
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);


return(
    <>
      <I18nextProvider i18n={i18n}>
        <div className="bg-white flex justify-between items-center h-20 mx-auto px-4 text-white mb-6 lg:w-full lg:text-md">
          <img src={logoWalt3} className="w-35 h-20 ml-2 mt-4 z-40" />
          <ul className="hidden lg:flex md:pt-0">
            <li
              onClick={handleHomeClick}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Home */}
              {t("Home")}
            </li>
            <li
              onClick={() => {
                navigate("/marketplace");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Marketplace */}
              {t("Marketplace")}
            </li>
            <li
              onClick={() => {
                navigate("/notifications");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Notifications */}
              {t("Notifications")}
            </li>
            <li
              onClick={() => {
                navigate("/chat");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Chat */}
              {t("Chat")}
            </li>
            <li
              onClick={() => {
                navigate("/postad");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* PostAd */}
              {t("PostAd")}
            </li>
            <li
              onClick={() => {
                navigate("/adslist");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* AdsList */}
              {t("AdsList")}
            </li>
            <li
              onClick={() => {
                navigate("/account");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Account */}
              {t("Account")}
            </li>
            <li
              onClick={() => {
                navigate("/faq");
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* FAQ */}
              {t("FAQ")}
            </li>
            <li
              onClick={() => {
                handleLogout();
              }}
              className="md:p-4 p-2 text-black hover:text-green-500 cursor-default"
            >
              {/* Logout */}
              {t("Logout")}
            </li>

            {/* for translation */}
            <li className=" flex flex-row justify-start items-center md:p-4 p-2 text-black hover:text-green-500 cursor-default">
              <span className="text-black">English</span>
              <button
                className="text-2xl px-2 text-black"
                onClick={toggleLanguage}
              >
                {currentLanguage === "en" ? (
                  <FaToggleOff />
                ) : (
                  <FaToggleOn />
                )}
              </button>
              <span className="text-black">বাংলা</span>
            </li>
          </ul>

          <div
            onClick={handleNav}
            className="fixed top-4 right-4 block lg:hidden text-black "
          >
            {!nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
          </div>

          <div
            className={
              nav
                ? "fixed left-0 top-0 w-[75%] h-[100%] border-r border-r-gray-900 bg-white ease-in-out duration-500 z-40"
                : "ease-in-out duration-500 fixed left-[-100%]"
            }
          >
            <img src={logoWalt3} className="w-35 h-20 ml-2 mt-4" />
            <ul className="p-4">
              <li
                onClick={handleHomeClick}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("Home")}
              </li>
              <li
                onClick={() => {
                  navigate("/marketplace");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("Marketplace")}
              </li>
              <li
                onClick={() => {
                  navigate("/notifications");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("Notifications")}
              </li>
              <li
                onClick={() => {
                  navigate("/chat");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("Chat")}
              </li>
              <li
                onClick={() => {
                  navigate("/postad");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("PostAd")}
              </li>
              <li
                onClick={() => {
                  navigate("/adslist");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("AdsList")}
              </li>
              <li
                onClick={() => {
                  navigate("/account");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("Account")}
              </li>
              <li
                onClick={() => {
                  navigate("/faq");
                }}
                className="p-4 border-b border-gray-600 text-black hover:text-green-500 cursor-default"
              >
                {t("FAQ")}
              </li>
              <li
                onClick={() => {
                  handleLogout();
                }}
                className="p-4 text-black hover:text-green-500 cursor-default"
              >
                {t("Logout")}
              </li>
              {/* for translation */}
              <li className=" flex flex-row justify-start items-center md:p-4 p-2 text-black hover:text-green-500 cursor-default">
                <span className="text-black">English</span>
                <button
                  className="text-2xl px-2 text-black"
                  onClick={toggleLanguage}
                >
                  {currentLanguage === "en" ? (
                    <FaToggleOff />
                  ) : (
                    <FaToggleOn />
                  )}
                </button>
                <span className="text-black">বাংলা</span>
              </li>
            </ul>
          </div>
        </div>
      </I18nextProvider>
    </>
)

}
export default Navbar