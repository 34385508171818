import React, { useEffect, useState } from "react";
import NewNav from "./NewNav";
import Profile from "./Profile";
import History from "./History";
import Footer from "./Footer";
import Loading from "./Loading";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  where,
  arrayUnion,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { useUserAuth } from "../context/UserAuthContext";

const Account = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const { user } = useUserAuth();

  const getHistory = async () => {
    setIsLoading(true);
    const q = query(collection(db, "users"), where("email", "==", user.email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setHistory(doc.data().trades);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />
          <Profile />
          <div className="min-h-100vh container mx-auto flex-grow ">
            <div className="relative sm:max-w-xl mx-auto text-center">
              <span className="text-3xl mb-4 text-green-900 font-bold mb-30 font-header">
                Trade History
              </span>
              {history.length === 0 && <p>No trades completed.</p>}
              {history.map((trade) => {
                return <History data={trade} />;
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
