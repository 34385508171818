import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import logoWalt3 from "../images/logoWalt3.png";
import Loading from "./Loading";
import EmailSentModal from "./EmailSentModal";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailSentModal, setShowEmailSentModal] = useState(false);
  const navigate = useNavigate();

  const { forgotPass } = useUserAuth();

  const sendEmail = async () => {
    try {
      setIsLoading(true);
      await forgotPass(email);
      //setSuccess("Email sent!");
      openEmailSentModal();
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
      setErr(err.message);
      setIsLoading(false);
    }
  };

  const openEmailSentModal = () => setShowEmailSentModal(true); // Define a method to open the modal
  const closeEmailSentModal = () => setShowEmailSentModal(false); // Define a method to close the modal
  const handleBackToHomeClick = () => {
    // Checks if screen width is less than or equal to a certain pixel value
    // (e.g., 768 for typical tablet screen width)
    if (window.innerWidth <= 768) {
      navigate("/loginorsignup");
    } else {
      navigate("/");
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="min-h-screen bg-gray-100 text-gray-800 antialiased px-4 py-10 flex flex-col justify-center sm:py-12">
          <div className="relative py-3 sm:max-w-xl mx-auto text-center">
            <span className="text-3xl mb-10 text-green-900 font-bold">
              Forgot Password
            </span>

            {err && (
              <div className="shadow-lg rounded-lg bg-red-900 px-5 py-2 mb-5 mt-10">
                <p className="block font-semibold text-center text-white">
                  {err}
                </p>
              </div>
            )}

            {success && (
              <div className="shadow-lg rounded-lg bg-green-900 px-5 py-2 mb-5">
                <p className="block font-semibold text-center text-white">
                  {success}
                </p>
              </div>
            )}

            <div className="relative mt-4 bg-white shadow-lg sm:rounded-lg text-left mb-5">
              <div className="h-2 bg-green-900 rounded-t-md"></div>
              <div className="py-10 px-10 mb-0">
                <img
                  src={logoWalt3}
                  className="w-35 h-20 ml-auto mr-auto mt-0 text-center"
                />
                <label className="block font-semibold">Email</label>
                <input
                  type="text"
                  onChange={(e) => setEmail(e.target.value)}
                  className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
                  required
                />

                {/* <label className="block mt-3 font-semibold">New Password</label>
                        <input
                            type="password"
                            className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
                            required
                        />

                        <label className="block mt-3 font-semibold">Confirm Password</label>
                        <input
                            type="password"
                            className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
                            required
                        /> */}

                <div className="flex justify-between items-baseline">
                  <button
                    onClick={sendEmail}
                    className="mt-4 mb-10 bg-green-900 text-white hover:bg-green-600 py-2 px-6 rounded-lg"
                  >
                    Send email
                  </button>
                </div>
              </div>
            </div>
            <p className="text-md text-center mt-10">
              Remember your password?{" "}
              <Link to="/login" className="hover:underline">
                Login
              </Link>
            </p>

            <p className="text-md text-center mt-5 text-green-900">
              <span
                onClick={handleBackToHomeClick}
                className="hover:underline cursor-pointer"
              >
                Back to home
              </span>
            </p>
          </div>
        </div>
      )}
      <EmailSentModal showModal={showEmailSentModal} closeModal={closeEmailSentModal} /> {/* Add the modal to your component */}
    </div>
  );
};

export default ForgotPassword;
