import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { sendEmailVerification } from "firebase/auth";

const HeroLogin = () => {
  const navigate = useNavigate();
  const [sent, setSent] = useState("");
  const { user } = useUserAuth();

  function verify(user) {
    sendEmailVerification(user)
      .then(() => {
        setSent("Email Sent! Please verify your email address and log in again.");
      })
      .catch((err) => {
        console.log(err.message);
        setSent("Error sending email.");
      });
  }

  return (
    <>
      <div className="bg-black text-white flex justify-between items-center">
        <div className="max-w-[800px] mt-[96px] w-full pb-32 mx-auto text-center flex flex-col">
          <p className="text-[#4b9f7f] font-header font-bold animate-fadeIn">
            Unlock a world of cashless exchanges.
          </p>
          <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-2 animate-fadeIn">
            Find your trade.
          </h1>
          <div className="flex justify-center items-center ">
            <p className="md:text-4xl sm:text-3xl text-md font-bold mt-2 text-gray-400 animate-fadeIn">
              When the world isn't free.
            </p>
          </div>
          {user.emailVerified ? (
            <>
              <button
                onClick={() => {
                  navigate("/marketplace");
                }}
                className="bg-[#68cda6] w-[180px] rounded-md font-medium text-lg my-8 mx-auto py-2 mb-4 text-black hover:scale-105 duration-300"
              >
                Trade Now
              </button>
              <div className="bg-gray-600 text-white text-sm font-regular mx-auto w-[60px] rounded-md py-1 text-center mt-0">
                Beta
              </div>
            </>

          ) : null}

          {!user.emailVerified ? (
            <div>
              <button
                onClick={() => {
                  verify(user);
                }}
                className="bg-[#68cda6] w-[180px] rounded-md font-medium text-lg my-8 mx-auto py-2 text-black hover:scale-105 duration-300"
              >
                Verify Email
              </button>
            </div>
          ) : null}
          {sent ? <p>{sent}</p> : null}

        </div>
      </div>
    </>
  );
};

export default HeroLogin;
