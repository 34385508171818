import React, { useRef } from "react";

const FeedSearch = ({ onSearchTermChange }) => {
  const selectRef = useRef();

  const handleChange = () => {
    onSearchTermChange(selectRef.current.value);
  };

  return (
    <div className="flex items-center mb-10 ml-10 mt-10 ">
      <div className="flex space-x-1">
        <input
          ref={selectRef}
          type="text"
          className="block w-full px-4 py-2 text-green-900 bg-white border border-green-600 rounded-full focus:border-green-400 focus:ring-green-300 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Search..."
        />
        <button
          onClick={handleChange}
          className="px-4 text-white bg-green-900 hover:bg-green-600 rounded-full "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default FeedSearch;
