import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

const Profile = () => {
  const { user } = useUserAuth();

  return (
    <div className="items-center">
      <div className="block max-w-sm p-6 bg-white border border-white rounded-lg shadow-lg shadow-green-200 mr-auto ml-auto mb-20 mt-20 hover:scale-105 duration-300">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-black">
          {user.displayName}
        </h5>
        <p className="font-normal text-black mb-10">{user.email}</p>
        <Link
          to="/adslist"
          className="hover:underline mt-3 text-green-900 font-light"
        >
          View posted ads
        </Link>
        <br></br>
        <Link
          to="/reportform"
          className="text-sm hover:underline mt-5 text-green-900 font-light">
          Report an issue
        </Link>
      </div>
    </div>
  );
};

export default Profile;
