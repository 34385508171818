import React from 'react';
import one from '../images/1.png';
import two from '../images/2.png';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.png';
import six from '../images/6.png';
import seven from '../images/7.png';
import eight from '../images/8.png';
import nine from '../images/9.png';
import ten from '../images/10.png';
import eleven from '../images/11.png';
import twelve from '../images/12.png';
import NewNav from "./NewNav";
import { InView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './carousel-overrides.css';


const Faq = () => {
    return (
        <div>
            <NewNav />
            <div className="flex mb-10 items-center justify-center h-48 bg-gradient-to-l to-green-800 from-green-700 shadow-xl">
                <h1 className="text-5xl font-bold text-white dark:text-white md:text-7xl sm:text-6xl lg:text-5xl">
                    <span className="text-white bg-clip-text">FAQ</span>
                </h1>
            </div>
            <h1 className='bg-white text-green-900 md:text-4xl sm:text-3xl text-3xl text-center pt-0 font-bold mb-10'>How to use the app?</h1>
            <div className="max-w-5xl mx-auto mb-20">
                <Carousel showThumbs={true} showStatus={false} showIndicators={true}>
                    <div><img src={one} alt="Image 1" /></div>
                    <div><img src={two} alt="Image 2" /></div>
                    <div><img src={three} alt="Image 3" /></div>
                    <div><img src={four} alt="Image 4" /></div>
                    <div><img src={five} alt="Image 5" /></div>
                    <div><img src={six} alt="Image 6" /></div>
                    <div><img src={seven} alt="Image 7" /></div>
                    <div><img src={eight} alt="Image 8" /></div>
                    <div><img src={nine} alt="Image 9" /></div>
                    <div><img src={ten} alt="Image 10" /></div>
                    <div><img src={eleven} alt="Image 11" /></div>
                    <div><img src={twelve} alt="Image 12" /></div>
                </Carousel>
            </div>
        </div>
    )
}

export default Faq;
