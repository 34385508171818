import React, { useState, useEffect } from "react";
import { choices } from "../choices";

const Filter = ({ updateTypes, updateCategories }) => {
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);

  const handleCategoryChange = (event) => {
    const { value } = event.target;
    if (category.includes(value)) {
      setCategory(category.filter((item) => item !== value));
    } else {
      setCategory([...category, value]);
    }
  };

  const handleTypeChange = (event) => {
    const { value } = event.target;
    if (type.includes(value)) {
      setType(type.filter((item) => item !== value));
    } else {
      setType([...type, value]);
    }
  };

  useEffect(() => {
    setCategory([]);
    updateTypes(type);
  }, [type]);

  useEffect(() => {
    updateCategories(category);
  }, [category]);

  const toggleFilter = () => {
    const filter = document.querySelector(".filter");
    filter.classList.toggle("hidden");
  };

  return (
    <div className="relative mt-10">
      <button
        type="button"
        onClick={toggleFilter}
        className="absolute right-0 top-0 mr-2 mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        Filter
      </button>
      <div className="filter hidden bg-white absolute right-0 top-0 w-64 mt-12 rounded-md shadow-lg">
        <div className="px-4 py-2 font-bold">Type</div>
        {Object.keys(choices).map((typ) => {
          return (
            <label key={typ} className="flex items-center px-4 py-2">
              <input
                type="checkbox"
                value={typ}
                className="form-checkbox h-5 w-5 text-green-600"
                checked={type.includes(typ)}
                onChange={handleTypeChange}
              />
              <span className="ml-2 text-gray-700">
                {typ.charAt(0).toUpperCase() + typ.slice(1)}
              </span>
            </label>
          );
        })}

        <div className="px-4 py-2 font-bold">Category</div>

        {type.length === 0 || type.length === 2
          ? Object.keys(choices).map((typ) => {
            return Object.keys(choices[typ]).map((cat) => {
              return (
                <label
                  key={choices[typ][cat]}
                  className="flex items-center px-4 py-2"
                >
                  <input
                    type="checkbox"
                    value={choices[typ][cat]}
                    className="form-checkbox h-5 w-5 text-green-600"
                    checked={category.includes(choices[typ][cat])}
                    onChange={handleCategoryChange}
                  />
                  <span className="ml-2 text-gray-700">{cat}</span>
                </label>
              );
            });
          })
          : Object.keys(choices[type[0]]).map((cat) => {
            return (
              <label
                key={choices[type[0]][cat]}
                className="flex items-center px-4 py-2"
              >
                <input
                  type="checkbox"
                  value={choices[type[0]][cat]}
                  className="form-checkbox h-5 w-5 text-green-600"
                  checked={category.includes(choices[type[0]][cat])}
                  onChange={handleCategoryChange}
                />
                <span className="ml-2 text-gray-700">{cat}</span>
              </label>
            );
          })}
      </div>
    </div>
  );
};

export default Filter;
