import React from "react";
import { useUserAuth } from "../context/UserAuthContext";

const History = (props) => {
  const { user } = useUserAuth();

  return (
    <div>
      <div className="flex justify-center hover:scale-105 duration-300 mb-4">
        <div className="bg-white mt-0 px-4 py-3 rounded-lg shadow-md max-w-xs hover:shadow-green-200">
          <div className="flex items-center mt-3 rounded-lg px-1 py-1 cursor-pointer">
            {props.data.fromEmail === user.email ? (
              <div className="ml-3">
                <span className="font-medium text-md font-header">
                  <span className="font-semibold mb-2">Service/Product Received: </span>{props.data.offerFor}
                </span>
                <div className="flex">
                  <p className="text-md mr-1 font-header">
                    <span className="font-semibold mb-2">Service/Product Offered: </span>{props.data.offerName}
                  </p>
                </div>
                <span className="text-sm text-blue font-semibold">
                  Trade Date: {props.data.endDate}
                </span>
              </div>
            ) : (
              <div className="ml-3">
                <span className="font-medium text-md font-header">
                  <span className="font-semibold mb-2">Service/Product Received: </span> {props.data.offerName}
                </span>
                <div className="flex">
                  <p className="text-md mr-1 font-header">
                    <span className="font-semibold mb-2">Service/Product Offered: </span> {props.data.offerFor}
                  </p>
                </div>
                <span className="text-sm text-blue font-semibold">
                  Trade Date: {props.data.endDate}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
