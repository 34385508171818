import React from "react";
import { Input } from "@material-tailwind/react";

const ViewOffer = () => {
  return (
    <div className="grid place-items-center mt-20">
      <div className="max-w-sm p-6 grid place-items-center bg-white border border-gray-300 rounded-lg shadow-md h-full">
        <h1 className="text-3xl font-bold text-green-800 mb-5">View Offer</h1>
        <img
          className="object-cover w-60"
          src="https://cdn.pixabay.com/photo/2022/08/18/09/20/houses-7394390__340.jpg"
        />
        <h3 className="mt-5 text-lg font-medium ">Offered Services/Products</h3>
        <div className="w-72 mb-4 mt-4">
          <h2 className="mt-5 font-normal text-center">Details...</h2>
        </div>
        <button className="px-4 py-2 mt-10 text-sm text-green-100 bg-green-700 hover:bg-green-500 rounded shadow">
          Chat
        </button>
      </div>
    </div>
  );
};

export default ViewOffer;
