import React from 'react';
import Wireframe from '../images/wireframe.png';
import { InView } from 'react-intersection-observer';

const Tutorial = () => {
  return (
    <div>
      <h1 className='bg-white text-green-900 md:text-4xl sm:text-3xl text-3xl text-center pt-20 font-bold'>Trade Process</h1>
      <div className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto'>
          <InView triggerOnce>
            {({ inView, ref }) => (
              <img ref={ref} src={Wireframe} className={`${inView ? 'opacity-100 animate-fadeIn' : 'opacity-0'}`} />
            )}
          </InView>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
