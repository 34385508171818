import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import OfferDetails from "./OfferDetails";
import { db } from "../firebase";
import { setDoc, doc, getDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const NotificationBox = (props) => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const [err, setErr] = useState("");
  const navigate = useNavigate();

  function timeAgo(timestamp) {
    const now = new Date();
    const date = timestamp.toDate();
    const secondsAgo = Math.floor((now - date) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);

    if (daysAgo > 0) {
      return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
    } else if (hoursAgo > 0) {
      return `${hoursAgo} hour${hoursAgo === 1 ? "" : "s"} ago`;
    } else if (minutesAgo > 0) {
      return `${minutesAgo} minute${minutesAgo === 1 ? "" : "s"} ago`;
    } else {
      return `${secondsAgo} second${secondsAgo === 1 ? "" : "s"} ago`;
    }
  }

  const handleChatClick = async ({
    fromEmail,
    toEmail,
    toName,
    fromName,
    offerName,
    offerFor,
  }) => {
    const docRef = doc(db, "chatrooms", `${props.id}`);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      setDoc(doc(db, "chatrooms", `${props.id}`), {
        fromEmail,
        toEmail,
        fromName,
        toName,
        offerName,
        offerFor,
        createdAt: serverTimestamp(),
        archived: false,
        status: false,
      })
        .then(() => {
          navigate("/chat");
        })
        .catch(() => {
          setErr("Error creating chat room.");
        });
    } else {
      navigate("/chat");
    }
  };

  return (
    <div className="p-4">
      <div className="flex py-4 flex-col items-start justify-center p-6 bg-white rounded-lg shadow-lg shadow-green-100 hover:shadow-green-300 border-10 cursor-pointer hover:shadow-xl transition-shadow duration-300" onClick={openModal}>
        {/* <div className="bg-white mt-0 px-4 py-3 rounded-lg shadow-md max-w-xs w-[800px]"> */}
        <div className="flex items-center mt-0 rounded-lg px-1 py-1 cursor-pointer">
          <div className="ml-3">
            {err && (
              <p className="block font-semibold text-center text-red-500">
                {err}
              </p>
            )}
            <span className="font-bold text-md">{props.data.fromName}</span>

            <p className="text-md mr-1 mt-2">Made an offer.</p>
            <div className="flex mt-0 mb-2 items-center">
              <p className="text-md text-black">Click to view.</p>
              <FaArrowRight
                size={30}
                onClick={openModal}
                className="fill-black hover:scale-105  duration-10 ml-4 hover:fill-green-600"
              />
            </div>
            <span className="text-sm text-blue font-semibold">
              {timeAgo(props.data.timestamp)}
            </span>
          </div>
        </div>
      </div>
      <OfferDetails showModal={showModal} closeModal={closeModal}>
        <h2><span className="font-bold">Offered: </span>{props.data.offerName}</h2>
        <h2><span className="font-bold">For: </span>{props.data.offerFor}</h2>
        <p><span className="font-bold">Details: </span> {props.data.offerDetails}</p>
        <button
          className="mt-4 px-4 py-2 mr-6 text-white bg-green-900 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-opacity-50"
          onClick={() => {
            handleChatClick(props.data);
            closeModal();
          }}
        >
          Chat
        </button>
      </OfferDetails>
    </div>
  );
};

export default NotificationBox;