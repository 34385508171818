import React from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import './FindTradeHome.css';



const FINDTRADEHOME = () => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);

    useEffect(() => {
        const buttonElem = buttonRef.current;
        const handleTransitionEnd = (e) => {
            if (e.propertyName === 'transform') {
                handleNavigate();
            }
        };

        buttonElem.addEventListener('transitionend', handleTransitionEnd);

        return () => {
            buttonElem.removeEventListener('transitionend', handleTransitionEnd);
        };
    }, []);

    const handleNavigate = () => {
        navigate('/marketplace');
    }
    return (
        <>
            <main className='bg-[#141414] custom-class'>
                <div className="sm:w-screen md:w-[390px] h-screen mx-auto bg-[#141414] drop-shadow-xl">

                    <div className="p-11 mx-auto overflow-hidden absolute">
                        <div className="h-[375px] w-[375px] rounded-full bg-[#303030] -ml-32 -mt-32 bg-opacity-60 circle"></div>
                        <h1 className="text-[64px] font-black text-white lg:-mt-28 sm:-mt-30 tracking-wide leading-tight font-poppins text">
                            Find your trade.
                        </h1>
                        <h2 className="text-3xl font-extrabold text-[#C6BDBD] mt-5 font-roboto text2">
                            When the world isn’t free.
                        </h2>
                        {/* <button className="w-full p-5 rounded-[10px] bg-[#0E9A0E] text-white font-bold mt-10 font-roboto text hover:bg-color">
              <span>Trade Now</span>
            </button> */}
                        <button ref={buttonRef} className="button-diagonal relative border-2 border-[#0E9A0E] bg-[#ECF6E3] bg-transparent w-full p-5 mt-10 font-medium text-white font-md rounded-[10px]" onClick={handleNavigate}>
                            <span>Explore Jackpt</span>
                        </button>



                        {/* <button className="relative border-2 border-gray-800 bg-transparent py-2.5 px-5 font-medium uppercase text-gray-800 transition-colors before:absolute before:left-0 before:top-0 before:-z-10 before:h-full before:w-full before:origin-top-left before:scale-x-0 before:bg-gray-800 before:transition-transform before:duration-300 before:content-[''] hover:text-white before:hover:scale-x-100">
              <span>Trade Now</span>
            </button> */}


                    </div>
                </div>
            </main>
        </>
    )
}

export default FINDTRADEHOME
