import React, { useState, useEffect, useRef } from "react";
import "../Chat.css";
import { PaperAirplaneIcon } from "@heroicons/react/outline";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  where,
  arrayUnion,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { useUserAuth } from "../context/UserAuthContext";
import TradeStatusModal from "./TradeStatusModal";
import styles from "./Inbox.module.css";

function Inbox(props) {
  const [input, setInput] = useState("");
  const [room, setRoom] = useState({});
  const [messages, setMessages] = useState({});
  const [showTradeStatusModal, setShowTradeStatusModal] = useState(false);
  const [tradeSuccess, setTradeSuccess] = useState(false);
  const [successClicked, setSuccessClicked] = useState(false);

  const { user } = useUserAuth();
  const openTradeStatusModal = () => setShowTradeStatusModal(true);
  const closeTradeStatusModal = () => setShowTradeStatusModal(false);
  const scrollRef = useRef(null);

  const success = async () => {
    let userSnapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", user.email))
    );
    let userDoc = userSnapshot.docs[0];
    let currentDate = new Date();
    currentDate = currentDate.toISOString().split("T")[0];
    let data = { ...room, endDate: currentDate };
    await updateDoc(userDoc.ref, {
      trades: arrayUnion(data),
    });
    let other_email = "";
    if (room.fromEmail === user.email) {
      other_email = room.toEmail;
    } else {
      other_email = room.fromEmail;
    }
    userSnapshot = await getDocs(
      query(collection(db, "users"), where("email", "==", other_email))
    );
    userDoc = userSnapshot.docs[0];
    await updateDoc(userDoc.ref, {
      trades: arrayUnion(data),
    });
    await updateDoc(doc(db, "chatrooms", `${props.room}`), {
      archived: true,
      status: true,
    });
    setTradeSuccess(true);
    setSuccessClicked(true);
    openTradeStatusModal();
  };

  const failed = async () => {
    await updateDoc(doc(db, "chatrooms", `${props.room}`), {
      archived: true,
    });
    setTradeSuccess(false);
    openTradeStatusModal();
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = async (e) => {
    e.preventDefault();

    const docRef = doc(db, "chatrooms", `${props.room}`);
    const subcollectionRef = collection(docRef, "messages");

    try {
      const newDocRef = await addDoc(subcollectionRef, {
        receiver: user.email === room.fromEmail ? room.toEmail : room.fromEmail,
        sender: user.email,
        timestamp: serverTimestamp(),
        content: input,
      });
      setInput("");
      console.log("New document added to subcollection");
    } catch (error) {
      console.error("Error adding document to subcollection:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "chatrooms", `${props.room}`),
      (docSnapshot) => {
        setRoom(docSnapshot.data());
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const docRef = doc(db, "chatrooms", `${props.room}`);
    const subcollectionRef = collection(docRef, "messages");
    let temp = {};

    const unsubscribe = onSnapshot(
      subcollectionRef,
      (subcollectionSnapshot) => {
        const subcollectionData = subcollectionSnapshot.docs.map(
          (doc) => (temp[doc.id] = doc.data())
        );

        setMessages({ ...temp });
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return (

    <div className={styles.inbox_wrapper}>
      <div className={styles.inbox}>
        <div className={styles.inbox_header}>
          <div className={`${styles.inbox_headerInfo} flex`}>
            <h3 className="p-2 text-white font-semibold text-md bg-gradient-to-r from-green-500 to-gray-500 bg-clip-text text-transparent">
              {room.fromEmail == user.email ? "You" : room.fromName} offered{" "}
              {room.offerName} for {room.offerFor}
            </h3>

          </div>
        </div>
        <div className="flex justify-end">
          {!successClicked && (
            <div className={`check_icon ${styles.check_icon}`} onClick={success}>
              <svg
                className="w-8 h-8 text-green-900 hover:text-green-700"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <circle cx="12" cy="12" r="10" />
                <path d="M9 12l2 2 4-4" />
              </svg>
            </div>
          )}
          {!room.archived && (
            <div className={`${styles.cross_icon_container}`}>
              <div className={`cross_icon ${styles.cross_icon}`} onClick={failed}>
                <svg
                  className="w-8 h-8 text-red-900 hover:text-red-700"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M15 9l-6 6M9 9l6 6" />
                </svg>
              </div>
            </div>

          )}

        </div>


        <div className="inbox_body" ref={scrollRef}>
          {Object.entries(messages)
            .filter(([, data]) => data.timestamp)
            .sort(
              ([, a], [, b]) =>
                (a.timestamp ? a.timestamp.toMillis() : 0) -
                (b.timestamp ? b.timestamp.toMillis() : 0)
            )

            .map(([key, data]) => {
              return (
                <p
                  key={key}
                  className={`inbox_message mb-2 mt-2 ${data.sender == user.email && "message_receiver"
                    }`}
                >
                  {data.content}
                </p>
              );
            })}
        </div>

        <div className="inbox_footer">
          {!room.archived ? (
            <form onSubmit={sendMessage}>
              <input
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                type="text"
                placeholder="Type a message"
              />

              <PaperAirplaneIcon
                className="h-8 w-8 text-green-900 hover:text-green-700 mt-4 mr-2 cursor-pointer"
                onClick={sendMessage}
              />

            </form>
          ) : null}
        </div>
        <TradeStatusModal
          showModal={showTradeStatusModal}
          closeModal={closeTradeStatusModal}
          isSuccess={tradeSuccess}
        />
      </div>


    </div>
  );
}

export default Inbox;
