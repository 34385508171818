import React, { useEffect, useState } from "react";
import NewNav from "./NewNav";
import NotificationBox from "./NotificationBox";
import Footer from "./Footer";
import Loading from "./Loading";
import { useUserAuth } from "../context/UserAuthContext";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase";

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState({});
  const { user } = useUserAuth();

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "offers"),
        where("toEmail", "==", user.email)
      );
      const querySnapshot = await getDocs(q);
      const temp = {};
      querySnapshot.forEach((doc) => {
        temp[doc.id] = doc.data();
      });
      setNotifications({ ...temp });
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />
          <div className="flex flex-col h-full">


            <div className="flex items-center justify-center h-48 bg-gradient-to-l to-green-800 from-green-700 shadow-xl">
              <h1 className="text-5xl font-bold text-white dark:text-white md:text-7xl sm:text-6xl lg:text-5xl">
                <span className="text-white bg-clip-text">Notifications</span>
              </h1>
            </div>


            <div className="flex-grow">
              <div className="container mx-auto py-12">
                <h1 className="mb-6 text-3xl font-extrabold text-center text-gray-900 dark:text-white">
                  Notifications
                </h1>
                {!(Object.keys(notifications).length === 0) ? (
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    {Object.entries(notifications)
                      .sort(
                        ([, a], [, b]) =>
                          b.timestamp.toMillis() - a.timestamp.toMillis()
                      )
                      .map(([key, data]) => {
                        return <NotificationBox key={key} id={key} data={data} />;
                      })}
                  </div>
                ) : (
                  <div className="ml-auto mr-auto text-center h-full w-full">
                    <h1 className="font-medium font-header text-2xl mb-5 mt-20">
                      You don't have any notifications 😞
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
