import React from 'react';
import premium2 from '../images/premium2.png';
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import './Plans.css';
import { InView } from 'react-intersection-observer';

const Plans = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  return (
    <div>
      <h1 className='bg-white text-green-900 md:text-4xl sm:text-3xl text-3xl text-center pt-20 font-bold'>Subscription Plans</h1>
      <div className='bg-white w-full py-20 px-2'>
        <div className='max-w-[1340px] mx-auto'>
          {/* Centered div with padding */}
          <div className='flex justify-center px-4 md:px-0'>
            {/* First Card */}
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className={`card w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 shadow-xl shadow-green-200 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ${inView ? 'opacity-100 animate-fadeIn' : 'opacity-0'}`}>
                  <img className='w-20 mx-auto mt-[-3rem] bg-white' src={premium2} />
                  <h2 className='text-2xl font-bold text-center py-8'>Monthly</h2>
                  <p className='text-center text-4xl font-bold'>৳71</p>
                  <div className='text-center font-regular'>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Ads</p>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Trades</p>
                    <p className='py-2 border-b mx-8 mt-8'>Recommendation Engine specifically designed to deliver personalized suggestions in the marketplace.</p>
                    <p className='py-2 border-b mx-8 mt-8'>BoloGPT, an AI-powered speech-to-text chatbot that helps the writing process for users easier.</p>
                    <p className='py-2 border-b mx-8 mt-8'>BoloGPT, an AI-powered speech-to-text chatbot that helps the writing process for users easier.</p>
                  </div>
                  <div className='flex-grow'></div>
                  <button className='bg-green-900 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-white hover:bg-green-500'>Subscribe Now</button>
                </div>
              )}
            </InView>
             {/* Second Card */}
             {/* <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className={`card w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 shadow-xl shadow-green-200 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ${inView ? 'opacity-100 animate-fadeIn' : 'opacity-0'}`}>
                  <img className='w-20 mx-auto mt-[-3rem] bg-white' src={premium2} />
                  <h2 className='text-2xl font-bold text-center py-8'>Quarterly Subscription</h2>
                  <p className='text-center text-4xl font-bold'>৳169</p>
                  <div className='text-center font-regular'>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Ads</p>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Trades</p>
                    <p className='py-2 border-b mx-8 mt-8'>Recommendation Engine specifically designed to deliver personalized suggestions in the marketplace.</p>
                    <p className='py-2 border-b mx-8 mt-8'>BoloGPT, an AI-powered speech-to-text chatbot that helps the writing process for users easier.</p>
                  </div>
                  <div className='flex-grow'></div>
                  <button className='bg-green-900 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-white hover:bg-green-500'>Subscribe Now</button>
                </div>
              )}
            </InView> */}
             {/* Third Card */}
             {/* <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className={`card w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 shadow-xl shadow-green-200 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ${inView ? 'opacity-100 animate-fadeIn' : 'opacity-0'}`}>
                  <img className='w-20 mx-auto mt-[-3rem] bg-white' src={premium2} />
                  <h2 className='text-2xl font-bold text-center py-8'>Half Yearly</h2>
                  <p className='text-center text-4xl font-bold'>৳299</p>
                  <div className='text-center font-regular'>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Ads</p>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Trades</p>
                    <p className='py-2 border-b mx-8 mt-8'>Recommendation Engine specifically designed to deliver personalized suggestions in the marketplace.</p>
                    <p className='py-2 border-b mx-8 mt-8'>BoloGPT, an AI-powered speech-to-text chatbot that helps the writing process for users easier.</p>
                  </div>
                  <div className='flex-grow'></div>
                  <button className='bg-green-900 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-white hover:bg-green-500'>Subscribe Now</button>
                </div>
              )}
            </InView> */}
             {/* First Card */}
             {/* <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref} className={`card w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 shadow-xl shadow-green-200 flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 ${inView ? 'opacity-100 animate-fadeIn' : 'opacity-0'}`}>
                  <img className='w-20 mx-auto mt-[-3rem] bg-white' src={premium2} />
                  <h2 className='text-2xl font-bold text-center py-8'>Yearly</h2>
                  <p className='text-center text-4xl font-bold'>৳499</p>
                  <div className='text-center font-regular'>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Ads</p>
                    <p className='py-2 border-b mx-8 mt-8'>Unlimited Trades</p>
                    <p className='py-2 border-b mx-8 mt-8'>Recommendation Engine specifically designed to deliver personalized suggestions in the marketplace.</p>
                    <p className='py-2 border-b mx-8 mt-8'>BoloGPT, an AI-powered speech-to-text chatbot that helps the writing process for users easier.</p>
                  </div>
                  <div className='flex-grow'></div>
                  <button className='bg-green-900 w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 text-white hover:bg-green-500'>Subscribe Now</button>
                </div>
              )}
            </InView> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
