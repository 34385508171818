import React from "react";
import "../modal.css";

const OfferDetails = ({ children, showModal, closeModal }) => {
  if (!showModal) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <h2 className="text-center font-bold text-lg mb-4 text-green-900">Offer Details</h2>
          {children}
          <button
            className="mt-10 px-4 py-2 text-white bg-red-700 rounded focus:outline-none focus:ring-2 focus:ring-red-700 focus:ring-opacity-50 hover:bg-red-500"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
