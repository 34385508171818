import React, { useState } from "react";
import { FcGoogle } from "react-icons/fc";
import GoogleButton from "react-google-button";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import LogoWalt3 from "../images/logoWalt3.png";
import Loading from "./Loading";
import { BiLeftArrowAlt } from 'react-icons/bi';

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const navigate = useNavigate();

  const { signUp, googleSignIn  } = useUserAuth();

  const customStyles = {
    backgroundColor: "DarkGreen",
    borderRadius: "6px",
    fontSize: "16px",
    cursor: "pointer",
  };
  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      await googleSignIn();
      setIsLoading(false);
      if (window.innerWidth <= 768) {
        navigate("/findtradehome");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsLoading(false);
    }
};


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreedToTerms) {
      setError('You must agree to the terms and conditions.');
      return;
    }
    try {
      setIsLoading(true);
      await signUp(name, email, password);
      setIsLoading(false);
      if (window.innerWidth <= 768) {
        navigate("/findtradehome");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsLoading(false);
    }
  };

  const handleBackToHomeClick = () => {
    // Checks if screen width is less than or equal to a certain pixel value
    // (e.g., 768 for typical tablet screen width)
    if (window.innerWidth <= 768) {
        // navigate("/loginorsignup");
        navigate("/");
    } else {
        navigate("/");
    }
};

return (
  <div className="flex flex-col h-screen bg-[#DDF5C9]">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col flex-1 justify-between">
        <div className="w-full md:w-[390px] mx-auto bg-[#DDF5C9] overflow-hidden ">
          <div className="bg-white mt-72 mb-0 p-6 rounded-tl-[48px] rounded-tr-[48px] shadow-lg drop-shadow">
            <div className="flex justify-center items-center mb-11"> 
              <button onClick={handleBackToHomeClick}><BiLeftArrowAlt className='text-4xl'></BiLeftArrowAlt></button>
              <h1 className='mx-auto text-3xl font-bold'>Sign Up</h1>
            </div>
            {error && <p className="text-red-500 text-center">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium">
                  Full name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="border border-[#DCF7C5] focus:border-2 focus:border-[#0E9A0E] outline-none text-sm rounded-lg w-full p-2.5"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium">
                  Email Address
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-[#DCF7C5] focus:border-2 focus:border-[#0E9A0E] outline-none text-sm rounded-lg w-full p-2.5"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="block mb-2 text-sm font-medium">
                  Password
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border border-[#DCF7C5] focus:border-2 focus:border-[#0E9A0E] outline-none text-sm rounded-lg w-full p-2.5"
                  required
                />
              </div>
              <div className="flex items-center mb-4">
                <input
                  id="terms"
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 accent-[#0E9A0E]"
                  required
                />
                <label htmlFor="terms" className="ml-2 text-sm font-medium">
                  I agree with the <Link to="/tac" className="text-green-600 hover:text-green-700">terms and conditions</Link>
                </label>
              </div>
              <div className="text-green-600 font-medium">It's easiest to</div>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="w-full p-2.5 bg-[#ECF6E3] flex gap-4 items-center rounded-md my-3 hover:scale-105 duration-300"
              >
                <FcGoogle className="w-6 h-6" /> Sign Up with Google
              </button>
              <button
                type="submit"
                className="w-full bg-[#0E9A0E] py-3 font-bold text-white rounded-md hover:text-[#ECF6E3] hover:scale-105 duration-300"
              >
                Sign Up
              </button>
            </form>
            <p className="text-center mt-4">
              Already have an account ?
              <Link to="/login" className="text-[#0E9A0E] pl-1"> Login</Link>
            </p>
          </div>
        </div>
      </div>
    )}
  </div>
);
};

export default SignUp;