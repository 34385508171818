import React, { useState } from "react";
import NewNav from "./NewNav";
import Footer from "./Footer";
import { Input } from "@material-tailwind/react";
import { useLocation } from "react-router-dom";
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import Loading from "./Loading";
import { useUserAuth } from "../context/UserAuthContext";
import OfferSentModal from "./OfferSentModal";
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';

const MakeOffer = () => {
  const location = useLocation();
  const data = location.state.data;
  const offerFor = data.name;
  const { user } = useUserAuth();

  const [offerName, setOfferName] = useState("");
  const [offerDetails, setOfferDetails] = useState("");
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [offerSent, setOfferSent] = useState("");
  const [showOfferSentModal, setShowOfferSentModal] = useState(false);
  const openOfferSentModal = () => setShowOfferSentModal(true);
  const closeOfferSentModal = () => setShowOfferSentModal(false);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/marketplace');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!offerName || !offerDetails) {
      setErr("Enter service/product name and details.");
      return;
    }

    setIsLoading(true);
    addDoc(collection(db, "offers"), {
      offerFor,
      offerName,
      offerDetails,
      toEmail: data.fromEmail,
      toName: data.fromName,
      fromName: user.displayName,
      fromEmail: user.email,
      timestamp: serverTimestamp(),
    })
      .then(() => {
        setIsLoading(false);
        openOfferSentModal(); // Open the OfferSentModal here
      })
      .catch((error) => {
        setIsLoading(false);
        setErr(error.message);
      });
  };

  return (
    <div>
      {!isLoading ? (
        <div>
          <NewNav />
          <form onSubmit={handleSubmit} className="mb-4 px-6">
            <div className="grid place-items-center mt-20">
              <div className="max-w-sm p-6 grid place-items-center bg-white border border-gray-300 rounded-lg shadow-md h-full">

                {/* Header section with back arrow and title */}
                <div className="flex items-center mb-5"> {/* Flex container */}
                  <button onClick={handleBack} className="text-green-900 hover:text-green-700">
                    <AiOutlineArrowLeft size={30} />
                  </button>
                  <h1 className="text-3xl font-bold text-green-900 ml-3"> {/* Adjust margin as needed */}
                    Make an offer
                  </h1>
                </div>

                {err && (
                  <div className="shadow-lg rounded-lg bg-green-900 px-5 py-2 mb-5">
                    <p className="block font-semibold text-center text-white">
                      {err}
                    </p>
                  </div>
                )}

                {/* {offerSent && (
                  <div className="shadow-lg rounded-lg bg-green-900 px-5 py-2 mb-5">
                    <p className="block font-semibold text-center text-white">
                      {offerSent}
                    </p>
                  </div>
                )} */}

                <img className="object-cover w-60" src={data.img} />


                <div className="w-60 mb-5">
                  <Input
                    onChange={(e) => {
                      setOfferName(e.target.value);
                    }}
                    placeholder="Service/Product Name"
                    className="p-2 mt-5"
                  />
                </div>
                <div className="w-60 mb-2 mt-2">
                  <textarea
                    onChange={(e) => {
                      const wordArray = e.target.value.split(' ');
                      if (wordArray.length <= 30) {
                        setOfferDetails(e.target.value);
                      } else {
                        setOfferDetails(wordArray.slice(0, 30).join(' '));
                      }
                    }}
                    placeholder="Service/Product Details (List services/products you offer in exchange for the desired item from others)."
                    className="p-4 w-full mt-5 mb-0 h-40 resize-none rounded shadow"
                    value={offerDetails}
                  />
                </div>

                <div className="relative">
                  <button
                    type="submit"
                    className="px-4 py-2 mt-10 text-sm text-green-100 bg-green-900 hover:bg-green-500 rounded shadow"
                  >
                    Offer
                  </button>
                </div>
              </div>
            </div>
          </form>
          <OfferSentModal showModal={showOfferSentModal} closeModal={closeOfferSentModal} /> {/* Add the OfferSentModal here */}
        </div>
      ) : (
        <Loading />
      )}
      {/* <OfferSentModal
        showModal={showOfferSentModal}
        closeModal={() => setShowOfferSentModal(false)}
      /> */}

    </div>
  );
};

export default MakeOffer;
