import React from "react";
import { Avatar } from "@material-ui/core";
import { useUserAuth } from "../context/UserAuthContext";
import styles from "./SidebarChat.module.css";

function SidebarChat(props) {
  const { user } = useUserAuth();

  return (
    <div className={styles.sidebarChat} onClick={props.onClick}>
      <div className={styles.sidebarChat_info}>
        <div className={styles.textContainer}>
          <h2 className="font-bold bg-gradient-to-r from-green-700 to-gray-600 bg-clip-text text-transparent">
            {props.room.fromEmail == user.email
              ? props.room.toName
              : props.room.fromName}
          </h2>
          <p>{props.lastMessage ? props.lastMessage.content : ""}</p>
        </div>
      </div>
    </div>
  );
}

export default SidebarChat;
