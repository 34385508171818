import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import LogoWalt from "../images/logoWalt.png";

const ResetPassword = () => {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-800 antialiased px-4 py-10 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl mx-auto text-center">
        <span className="text-3xl text-green-900 font-bold">
          Reset Password
        </span>

        <div className="relative mt-4 bg-white shadow-lg sm:rounded-lg text-left mb-5">
          <div className="h-2 bg-green-900 rounded-t-md"></div>
          <div className="py-10 px-10 mb-0">
            <img
              src={LogoWalt}
              className="w-35 h-20 ml-auto mr-auto mt-0 text-center"
            />
            <label className="block font-semibold">Email</label>
            <input
              type="text"
              className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
              required
            />

            <label className="block mt-3 font-semibold">New Password</label>
            <input
              type="password"
              className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
              required
            />

            <label className="block mt-3 font-semibold">Confirm Password</label>
            <input
              type="password"
              className=" border w-full h-5 px-10 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-1 focus:ring-green-900 rounded-md"
              required
            />

            <div className="flex justify-between items-baseline">
              <button className="mt-4 mb-10 bg-green-900 text-white hover:bg-green-600 py-2 px-6 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </div>
        <p className="text-md text-center mt-10">
          Remember your password?{" "}
          <Link to="/login" className="hover:underline">
            Login
          </Link>
        </p>

        <p className="text-md text-center mt-5 text-green-900">
          <Link to="/home" className="hover:underline">
            Back to home
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
