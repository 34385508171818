import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDjGKujgS1CU7U7-Bf0yi2Kzgo6vcI64Qg",
  authDomain: "barter-438af.firebaseapp.com",
  projectId: "barter-438af",
  storageBucket: "barter-438af.appspot.com",
  messagingSenderId: "598529979478",
  appId: "1:598529979478:web:b38706e3021b60d7b077f7",
  measurementId: "G-JQ9VJKHTW6",
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const auth = getAuth(firebaseApp);
