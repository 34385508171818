import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logoW from "../images/logoW.png";
import logoBalt from "../images/logoBalt.png";
import logoBalt3 from "../images/logoBalt3.png";
import logoWalt2 from "../images/logoWalt2.png";
import logoWalt3 from "../images/logoWalt3.png";
import { useUserAuth } from "../context/UserAuthContext";

const NewNavB = () => {
  const { logout } = useUserAuth();

  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="bg-black flex justify-between items-center h-20 mx-auto px-4 text-white mb-0 lg:w-full lg:text-md">
      <img src={logoBalt3} className="w-35 h-20 ml-2 mt-4 z-40" />
      <ul className="hidden lg:flex md:pt-0">
        <li
          onClick={() => {
            navigate("/");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Home
        </li>
        <li
          onClick={() => {
            navigate("/marketplace");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Marketplace
        </li>
        <li
          onClick={() => {
            navigate("/notifications");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Notifications
        </li>
        <li
          onClick={() => {
            navigate("/chat");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Chat
        </li>
        <li
          onClick={() => {
            navigate("/postad");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          PostAd
        </li>
        <li
          onClick={() => {
            navigate("/adslist");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          AdsList
        </li>
        <li
          onClick={() => {
            navigate("/account");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Account
        </li>
        <li
          onClick={() => {
            navigate("/faq");
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          FAQ
        </li>
        <li
          onClick={() => {
            handleLogout();
          }}
          className="md:p-4 p-2 text-white hover:text-green-500 cursor-default"
        >
          Logout
        </li>
      </ul>

      <div onClick={handleNav} className="block lg:hidden text-white ">
        {!nav ? <AiOutlineMenu size={20} /> : <AiOutlineClose size={20} />}
      </div>

      <div
        className={
          nav
            ? "fixed left-0 top-0 w-[75%] h-[100%] border-r border-r-gray-900 bg-black ease-in-out duration-500 z-40"
            : "ease-in-out duration-500 fixed left-[-100%]"
        }
      >
        <img src={logoBalt3} className="w-35 h-20 ml-2 mt-4" />
        <ul className="p-4">
          <li
            onClick={() => {
              navigate("/");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            Home
          </li>
          <li
            onClick={() => {
              navigate("/marketplace");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            Marketplace
          </li>
          <li
            onClick={() => {
              navigate("/notifications");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            Notifications
          </li>
          <li
            onClick={() => {
              navigate("/chat");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            Chat
          </li>
          <li
            onClick={() => {
              navigate("/postad");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            PostAd
          </li>
          <li
            onClick={() => {
              navigate("/adslist");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            AdsList
          </li>
          <li
            onClick={() => {
              navigate("/account");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            Account
          </li>
          <li
            onClick={() => {
              navigate("/faq");
            }}
            className="p-4 border-b border-gray-600 text-white hover:text-green-500 cursor-default"
          >
            FAQ
          </li>
          <li
            onClick={() => {
              handleLogout();
            }}
            className="p-4 text-white hover:text-green-500 cursor-default"
          >
            Logout
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NewNavB;
