import React, { useState } from "react";
import GoogleButton from "react-google-button";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import LogoWalt3 from "../images/logoWalt3.png";
import Loading from "./Loading";
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { FcGoogle } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [seePassword, setSeePassword] = useState(false);

  const { login, googleSignIn } = useUserAuth();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await login(email, password);
      setIsLoading(false);
      if (window.innerWidth <= 768) {
        navigate("/findtradehome");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsLoading(false);
    }
};


  const customStyles = {
    backgroundColor: "Green",
    borderRadius: "6px",
    fontSize: "16px",
    cursor: "pointer",
    width:"100%"
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      await googleSignIn();
      setIsLoading(false);
      if (window.innerWidth <= 768) {
        navigate("/findtradehome");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.log(err.message);
      setError(err.message);
      setIsLoading(false);
    }
};


  const handleBackToHomeClick = () => {
    // Checks if screen width is less than or equal to a certain pixel value
    // (e.g., 768 for typical tablet screen width)
    if (window.innerWidth <= 768) {
        navigate("/");
    } else {
        navigate("/");
    }
};

const handleSeePass = () => {
  setSeePassword(!seePassword);
};

return (
  <div>
    {isLoading ? (
      <Loading />
    ) : (
      <>
        <ToastContainer />
        <main className="bg-[#DDF5C9]">
          <section className="max-w-md relative bg-[#DDF5C9] min-h-screen mx-auto">
            <div className="bg-white absolute w-full bottom-0 py-6 rounded-t-[60px] shadow-lg">
              <div className="flex items-center mx-5 mb-8">
                <button onClick={handleBackToHomeClick}>
                  <BiLeftArrowAlt className="text-4xl" />
                </button>
                <h1 className="mx-auto text-3xl font-bold pr-5">
                  Login
                </h1>
              </div>

              <div className="max-w-xs mx-auto">
                {/* Email Input */}
                <div className="mb-3">
                  <label className="block mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    // Added 'border' and 'border-green-500' classes for a default border
                    className="py-3 px-5 border border-green-300 focus:border-2 focus:border-[#0E9A0E] outline-none rounded-lg w-full"
                    required
                  />
                </div>

               {/* Password Input */}
<div className="mb-3 relative max-w-xs mx-auto">
  <label className="block mb-2">
    Password
  </label>
  <input
    type={seePassword ? "text" : "password"}
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    className="py-3 px-5 border border-green-500 focus:border-2 focus:border-[#0E9A0E] outline-none rounded-lg w-full"
    required
  />
  <button onClick={handleSeePass} className="absolute inset-y-4 right-0 pr-3 flex items-center justify-center h-full">
    {seePassword ? (
      <AiFillEyeInvisible className="text-2xl text-green-600" />
    ) : (
      <AiFillEye className="text-2xl text-green-600" />
    )}
  </button>
</div>




                {error && (
                  <p className="text-red-500 mt-4 text-center">{error}</p>
                )}
                <div className="text-green-600 font-medium">It's easiest to</div>

                {/* Google Sign-In Button */}
                <div className="text-center mt-2">
                  <GoogleButton
                    onClick={() => {
                      handleGoogleSignIn();
                    }}
                    style={customStyles}
                  />
                </div>

                {/* Login Button */}
                <div className="mt-5">
                  <button
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="py-4 w-full bg-[#0E9A0E] rounded-[10px] font-bold text-white shadow-md hover:scale-105 duration-300"
                  >
                    Login
                  </button>
                </div>

                <div className="text-center my-4">
                  <p>
                    Don’t have an account?{" "}
                    <Link to="/signup" className="text-[#0E9A0E]">
                      Sign Up
                    </Link>
                  </p>
                </div>

                <div className="text-center">
                  <Link to="/forgotpassword" className="text-sm">
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    )}
  </div>
);
};

export default Login;