import React from 'react';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="bg-black text-white flex justify-between items-center">
        <div className="max-w-[800px] mt-[96px] w-full pb-32 mx-auto text-center flex flex-col">
          <p className="text-[#4b9f7f] font-bold font-header animate-fadeIn">
            Unlock a world of cashless exchanges.
          </p>
          <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-2 animate-fadeIn">
            Find your trade.
          </h1>
          <div className="flex justify-center items-center ">
            <p className="md:text-4xl sm:text-3xl text-md font-bold mt-2 text-gray-400 animate-fadeIn">
              When the world isn't free.
            </p>
          </div>
          <button
            onClick={() => {
              navigate('/login');
            }}
            className="bg-[#68cda6] w-[180px] mb-4 rounded-md font-medium text-lg my-8 mx-auto py-2 text-black hover:scale-105 duration-300"
          >
            Trade Now
          </button>
          <div className="bg-gray-600 text-white text-sm font-regular mx-auto w-[60px] rounded-md py-1 text-center mt-0">
                Beta
              </div>

        </div>
        
      </div>
      
    </>
  );
};

export default Hero;
