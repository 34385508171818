import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

function LoggedIn() {
  const { user, logout } = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div>
      <h1>
        LoggedIn <br /> {user.email} <br /> {user.displayName}
      </h1>
      <button
        onClick={() => {
          handleLogout();
        }}
      >
        Log out
      </button>
    </div>
  );
}

export default LoggedIn;
