import React from "react";
import Navbar from "./Navbar";
import NewNavB from "./NewNavB";
import Tutorial from "./Tutorial";
import Hero from "./Hero";
import HeroLogin from "./HeroLogin";
import Plans from "./Plans";
import Mission from "./Mission";
import Footer from "./Footer";
import { useUserAuth } from "../context/UserAuthContext";
import MarketplaceBanner from "./MarketplaceBanner";
import MarketplaceBannerLogin from "./MarketplaceBannerLogin";

function Home() {
  const { user } = useUserAuth();

  return (
    <div>
      {user ? <NewNavB /> : <Navbar />}
      {user ? <HeroLogin /> : <Hero />}
      <Tutorial />
      {user ? <MarketplaceBannerLogin /> : <MarketplaceBanner />}
      {/* <Plans /> */}
      <Mission />
      <Footer />
    </div>
  );
}

export default Home;
