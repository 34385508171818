import React, {useState} from "react";
import { Link } from "react-router-dom";
import LogoB from '../images/logoB.png'
import LogoBalt from '../images/logoBalt.png'
import LogoBalt3 from '../images/logoBalt3.png'
import LogoBalt2 from '../images/logoBalt2.png'

const Navbar = () => {

return(
    <div className="bg-black flex justify-between items-center h-24  w-full mx-auto px-4 text-white">
        <img src={LogoBalt3} className="w-35 h-20 ml-2 mt-4"/>
        <ul className="sticky flex md:pt-0">
            <Link to="/login" className="md:p-4 p-2  hover:text-green-400 cursor-default">Login</Link>
            <Link to="/signup" className="md:p-4 p-2  hover:text-green-400 cursor-default">Sign Up</Link>
        </ul>
        
    </div>
)

}
export default Navbar