import React from 'react';
import logo from '../images/logonobg.png';
import './LoginOrSignup.css';
import { Link } from "react-router-dom";

const LoginOrSignup = () => {
    return (
        <div className="login-or-signup flex flex-col items-center justify-center h-screen bg-black">
            <img src={logo} alt="Logo" className="mb-8 w-64" /> {/* Adjusted image size */}
            <Link to="/Login"><button className="login-button mb-4 bg-green-700 text-white font-light w-64 px-6 py-2 rounded text-center transform transition-all duration-300 hover:scale-105 hover:bg-green-600">
                Login
            </button></Link>
            <Link to="/signup"><button className="signup-button text-white border-green-700 border-2 font-light w-64 px-6 py-2 rounded text-center transform transition-all duration-300 hover:scale-105 hover:bg-green-600 hover:text-black">
                Sign Up
            </button></Link>
        </div>
    );
};

export default LoginOrSignup;
