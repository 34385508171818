import React, { useState, useEffect } from "react";
import "../Chat.css";
import { Avatar } from "@material-ui/core";
import { SearchOutlined, Menu, Close } from "@material-ui/icons";
import SidebarChat from "./SidebarChat";
import LogoWalt from "../images/logoWalt.png";
import LogoWalt3 from "../images/logoWalt3.png";
import { db } from "../firebase";
import {
  getDocs,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

import { useUserAuth } from "../context/UserAuthContext";

function Sidebar({ handleClick }) {
  const [rooms, setRooms] = useState([]);
  const [filteredRooms, setFilteredRooms] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const { user } = useUserAuth();

  const filterRooms = (searchValue) => {
    const filtered = Object.entries(rooms).filter(([, roomData]) => {
      const fromName = roomData.fromName.toLowerCase();
      const toName = roomData.toName.toLowerCase();
      return (
        fromName.includes(searchValue.toLowerCase()) ||
        toName.includes(searchValue.toLowerCase())
      );
    });
    setFilteredRooms(filtered);
  };

  const getRooms = async () => {
    const toEmailQuery = query(
      collection(db, "chatrooms"),
      where("toEmail", "==", user.email)
    );
    const fromEmailQuery = query(
      collection(db, "chatrooms"),
      where("fromEmail", "==", user.email)
    );

    const toEmailSnapshot = await getDocs(toEmailQuery);
    const fromEmailSnapshot = await getDocs(fromEmailQuery);

    let obj = {};

    const fetchLastMessage = async (roomId) => {
      const messagesQuery = query(
        collection(db, `chatrooms/${roomId}/messages`),
        orderBy("timestamp", "desc"),
        limit(1)
      );
      const messagesSnapshot = await getDocs(messagesQuery);
      return messagesSnapshot.docs[0]?.data() || null;
    };

    const processRoom = async (doc) => {
      const lastMessage = await fetchLastMessage(doc.id);
      obj[doc.id] = { ...doc.data(), lastMessage };
    };

    const toEmailPromises = toEmailSnapshot.docs.map((doc) => processRoom(doc));
    const fromEmailPromises = fromEmailSnapshot.docs.map((doc) =>
      processRoom(doc)
    );

    await Promise.all([...toEmailPromises, ...fromEmailPromises]);

    setRooms(obj);
  };

  useEffect(() => {
    setFilteredRooms(Object.entries(rooms));
  }, [rooms]);

  useEffect(() => {
    getRooms();
  }, []);

  return (
    <>
      <button
        className="sidebar_button"
        onClick={() => setSidebarVisible(!sidebarVisible)}
      >
        Show Chats
      </button>
      <div className={`sidebar ${sidebarVisible ? "sidebar_visible" : ""}`}>
        <div className="sidebar_header bg-white">
          <img src={LogoWalt3} className="w-35 h-20 ml-2 mt-4" />
        </div>

        <div className="sidebar_search">
          <div className="sidebar_searchContainer">
            <SearchOutlined />
            <input
              type="text"
              placeholder="Search chat"
              onChange={(e) => filterRooms(e.target.value)}
            />
          </div>
        </div>

        <div className="sidebar_chats">
          {filteredRooms
            .sort((a, b) => {
              return b[1].createdAt - a[1].createdAt;
            })
            .map(([room, roomData]) => {
              return (
                <SidebarChat
                  onClick={() => handleClick(room)}
                  key={room}
                  room={roomData}
                  lastMessage={roomData.lastMessage}
                />
              );
            })}
        </div>
        <div
          className="close_icon"
          onClick={() => setSidebarVisible(false)}
          style={{ display: sidebarVisible && window.innerWidth <= 768 ? "block" : "none" }}
        >
          <Close />
        </div>
      </div>
    </>
  );
}

export default Sidebar;