import React from "react";
import TextWithLineBreaks from "./TextWithLineBreaks";
import { db } from "../firebase";
import { doc, deleteDoc } from "firebase/firestore";

const PostedAd = (props) => {
  const deletePost = async () => {
    deleteDoc(doc(db, "posts", `${props.data.id}`))
      .then(() => {
        console.log("deleted");
        props.onDeletePost(props.data.id);
      })
      .catch((err) => {
        console.log("failed");
      });
  };

  return (
    <div className="w-full bg-white rounded-lg shadow-md shadow-green-100 hover:shadow-green-300 lg:flex lg:max-w-md mb-20 mr-10 hover:scale-105 duration-300">
      <img
        className="object-cover w-full lg:w-40 lg:h-38 rounded-t-lg lg:rounded-r-none lg:rounded-l-lg"
        src={props.data.img}
      />
      <div className="pl-2 py-4 flex flex-col justify-between h-full min-h-38 lg:min-h-0">
        <div className="flex-grow">
          <h4 className="text-2xl py-2 font-bold font-header text-green-900 mb-2 mt-2">
            {props.data.name}
          </h4>

          <div>
            <div className="flex mb-2 sm:mb-1">
              <p className="font-medium mr-2">Type:</p>
              <p>
                {props.data.type.charAt(0).toUpperCase() +
                  props.data.type.slice(1)}
              </p>
            </div>
            <div className="flex mb-4 sm:mb-2">
              <p className="font-medium mr-2">Category:</p>
              <p>
                {props.data.category
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </p>
            </div>
            <button
              onClick={deletePost}
              className="mt-4 ml-0 py-2 px-3 text-white bg-green-900 rounded hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-opacity-50 max-w-xs"
            >
              Delete post
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PostedAd;
