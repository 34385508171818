import React from 'react';
import { InView } from 'react-intersection-observer';

const Mission = () => {
    return (
        <>
            <div className="bg-[#a8f4d6] flex justify-between items-center">
                <div className="max-w-[800px] mt-[96px] w-full pb-20 mx-auto text-center flex flex-col">
                    <InView triggerOnce>
                        {({ inView, ref }) => (
                            <h1 ref={ref} className={`text-black md:text-7xl sm:text-6xl text-3xl font-bold md:py-2 ${inView ? 'animate-fadeIn' : ''}`}>
                                Swap. <span className="md:text-7xl sm:text-6xl text-3xl font-bold text-green-900">Save.</span> Sustain.
                            </h1>
                        )}
                    </InView>
                </div>
            </div>
        </>
    );
};

export default Mission;
