import React, { useRef, useState } from "react";
import { Input } from "@material-tailwind/react";
import { useUserAuth } from "../context/UserAuthContext";
import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Loading from "./Loading";
import NewNav from "./NewNav";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import { choices } from "../choices";
import { AiOutlineArrowLeft } from 'react-icons/ai';

const CreatePost = () => {
  const selectRef = useRef();
  const { user } = useUserAuth();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/marketplace');
  };

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [details, setDetails] = useState("");
  const [type, setType] = useState("service");
  const [err, setErr] = useState("");

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageUrl(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const category = selectRef.current.value;

    if (!name || !details) {
      setErr("Enter service/product name and details.");
      return;
    }

    if (!image && type == "product") {
      setErr("Must upload an image if the post is for a product.");
      setIsLoading(false);
    } else {
      const randInt =
        Math.floor(Math.random() * (Math.ceil(1000) - Math.ceil(1) + 1)) +
        Math.ceil(1);

      const imgName = `${user.uid}_${randInt}`;

      try {
        const storageRef = ref(storage, imgName);
        uploadBytes(storageRef, image).then((snapshot) => {
          getDownloadURL(storageRef).then((url) => {
            addDoc(collection(db, "posts"), {
              fromEmail: user.email,
              fromName: user.displayName,
              img: url,
              type,
              category,
              name,
              details,
            });
            console.log("Ad posted successfully!");
            setIsLoading(false);
            navigate("/adslist");
          });
        });
      } catch (error) {
        setErr(error.message);
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />
          <div className="grid place-items-center mt-20 mb-40">
            <div className="max-w-sm p-6 grid place-items-center bg-white border border-gray-300 rounded-lg shadow-md h-full">
               {/* Header section with back arrow and title */}
               <div className="flex items-center mb-5"> {/* Flex container */}
                  <button onClick={handleBack} className="text-green-900 hover:text-green-700">
                    <AiOutlineArrowLeft size={30} />
                  </button>
                  <h1 className="text-3xl font-bold text-green-900 ml-3"> {/* Adjust margin as needed */}
                    Post an ad
                  </h1>
                </div>
              {err && (
                <p className="block font-semibold text-center text-red-500">
                  {err}
                </p>
              )}

              <form
                onSubmit={handleSubmit}
                className="items-center justify-center ml-2 "
              >
                <div className="w-full mb-4">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-60 border-0 border-black rounded-lg cursor-pointer bg-green-700 dark:hover:bg-bray-800 dark:bg-green- hover:bg-green-100 dark:border-green-600 dark:hover:border-green-500 dark:hover:bg-green-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      {/* Display the image preview if imageUrl is available */}
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="Uploaded Preview"
                          className="w-64 h-60 object-cover m-0"
                        />
                      ) : (
                        <>
                          <svg
                            className="w-10 h-10 mb-3 text-white"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-white">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-white">
                            Image only
                          </p>
                        </>
                      )}
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>

                <div className="relative inline-block text-left mt-4 mr-6 mb-4">
                  <select
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    className="bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5 dark:bg-white dark:border-gray-300 dark:placeholder-white dark:text-black dark:focus:ring-white dark:focus:border-gray-300"
                  >
                    <option value="service">Service</option>
                    <option value="product">Product</option>
                  </select>
                </div>

                <div className="relative inline-block text-left mt-4 ml-6">
                  <div className="relative inline-block text-left mt-4">
                    <select
                      ref={selectRef}
                      className="bg-white border border-gray-300 text-black text-sm rounded-lg focus:ring-white focus:border-white block w-full p-2.5 dark:bg-white dark:border-gray-300 dark:placeholder-white dark:text-black dark:focus:ring-white dark:focus:border-gray-300"
                    >
                      {Object.keys(choices[type]).map((label) => {
                        return (
                          <option key={label} value={choices[type][label]}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="w-full mb-6 items-center">
                  <Input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Service/Product Name"
                    className="p-2 mt-5"
                  />
                </div>
                <div className="w-72">
                  <textarea
                    className="p-4 w-full mt-5 mb-0 h-40 resize-none rounded shadow"
                    onChange={(e) => {
                      const wordArray = e.target.value.split(" ");
                      if (wordArray.length <= 30) {
                        setDetails(e.target.value);
                      } else {
                        setDetails(wordArray.slice(0, 30).join(" "));
                      }
                    }}
                    placeholder="Service/Product Details (Highlight key details and you may also mention services/products you would like in exchange)."
                    value={details}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 text-sm text-green-100 bg-green-900 hover:bg-green-500 rounded shadow mt-5 "
                >
                  Post
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePost;
