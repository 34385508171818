// TradeStatusModal.js

import React from "react";

const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
};

const modalContentStyle = {
    backgroundColor: "white",
    padding: "1.5rem",
    borderRadius: "8px",
    maxWidth: "90%",
    boxSizing: "border-box",
    overflow: "auto",
};

const TradeStatusModal = ({ showModal, closeModal, isSuccess }) => {
    if (!showModal) return null;

    return (
        <div style={modalOverlayStyle} onClick={closeModal}>
            <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
                <div className="p-4">
                    <h2
                        className={`text-center font-bold text-lg mb-4 ${isSuccess ? "text-green-900" : "text-red-900"
                            }`}
                    >
                        {isSuccess ? "Trade Successful" : "Trade Cancelled"}
                    </h2>
                    <p className="text-center">
                        {isSuccess
                            ? "The trade has been successfully completed!"
                            : "The trade has been cancelled."}
                    </p>
                    <div className="flex justify-center mt-10">
                        <button
                            className={`px-4 py-2 text-white ${isSuccess ? "bg-green-900" : "bg-red-900"
                                } rounded focus:outline-none focus:ring-2 ${isSuccess
                                    ? "focus:ring-green-900"
                                    : "focus:ring-red-900"
                                } focus:ring-opacity-50 ${isSuccess ? "hover:bg-green-700" : "hover:bg-red-700"
                                }`}
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TradeStatusModal;
