import { createContext, useContext } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { collection, addDoc, query, getDocs, where } from "firebase/firestore";
import { db } from "../firebase";
import { auth } from "../firebase";
import { useEffect, useState } from "react";


// import { onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";
// // Set the persistence
// setPersistence(auth, browserSessionPersistence)
//   .then(() => {
//     // Persistence is set. Now you can call onAuthStateChanged
//     onAuthStateChanged(auth, (user) => {
//       // your existing onAuthStateChanged logic here
//     });
//   })
//   .catch((error) => {
//     console.error("Error setting persistence:", error);
//   });

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState("");

  // Add an updateUser method that accepts updates and merges them with the current user state
  // const updateUser = (updates) => {
  //   setUser((currentUser) => ({ ...currentUser, ...updates }));
  // };


  async function signUp(name, email, password) {
    const response = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await updateProfile(response.user, { displayName: name });
    return createUserFirestore(name, email);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    signOut(auth);
  }

  async function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    const response = await signInWithPopup(auth, googleAuthProvider);
    const name = response.user.displayName;
    const email = response.user.email;

    const exist = await checkUserExist(email);
    if (!exist) {
      return createUserFirestore(name, email);
    } else {
      return true;
    }
  }

  async function forgotPass(email) {
    await sendPasswordResetEmail(auth, email);
  }

  function createUserFirestore(name, email) {
    return addDoc(collection(db, "users"), {
      name,
      email,
      trades: [],
      // hasTakenTour: false
    });
  }

  async function checkUserExist(email) {
    const q = query(collection(db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      return true; // user exists
    } else {
      return false; // user does not exist
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, signUp, login, logout, googleSignIn, forgotPass }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
