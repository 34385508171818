import React, { useState, useEffect } from "react";
import NewNav from "./NewNav";
import PostedAd from "./PostedAd";
import Footer from "./Footer";
import Loading from "./Loading";
import { useUserAuth } from "../context/UserAuthContext";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";

const AdsList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [adsList, setAdsList] = useState({});
  const { user } = useUserAuth();

  const handleDeletePost = (postId) => {
    const updatedAdsList = { ...adsList };
    delete updatedAdsList[postId];
    setAdsList(updatedAdsList);
  };

  const getAds = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "posts"),
        where("fromEmail", "==", user.email)
      );
      const querySnapshot = await getDocs(q);
      const temp = {};
      querySnapshot.forEach((doc) => {
        temp[doc.id] = doc.data();
      });
      setAdsList({ ...temp });
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div>
          <NewNav />

          <div className="flex mb-20 items-center justify-center h-48 bg-gradient-to-l to-green-800 from-green-700 shadow-xl">
            <h1 className="text-5xl font-bold text-white dark:text-white md:text-7xl sm:text-6xl lg:text-5xl">
              <span className="text-white bg-clip-text">Posted Ads</span>
            </h1>
          </div>


          {!(Object.keys(adsList).length === 0) ? (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 ml-10 mr-10">
              {Object.keys(adsList).map((key) => {
                return (
                  <PostedAd
                    key={key}
                    data={{ ...adsList[key], id: key }}
                    onDeletePost={handleDeletePost}
                  />
                );
              })}
            </div>
          ) : (
            <div className="ml-auto mr-auto text-center h-full w-full">
              <h1 className="font-medium font-header text-2xl mb-5 mt-20">
                You haven't posted any ads yet 😞
              </h1>
              <Link
                to="/postad"
                className="hover:underline hover:text-green-700"
              >
                Post a new ad?
              </Link>
            </div>
          )}
          {/* lg:mb-[1000px] md:mb-[700px] sm:mb-[300px] */}

        </div>
      )}
    </div>
  );
};

export default AdsList;
