import React from "react";
import { FaLinkedin, FaPhone, FaEnvelope, FaFacebook, FaInstagram } from "react-icons/fa";
import LogoBalt from '../images/logoBalt.png'
import LogoBalt3 from '../images/logoBalt3.png'

const Footer = () => {
  return (
    <div className="bg-black w-full mx-auto text-center mb-0">
      <img src={LogoBalt3} className="w-35 h-20 ml-auto mr-auto pt-4 mb-5 text-center"/>
      <div className="bg-black flex justify-center mt-0 mb-10">
      <a href= "http://www.facebook.com/jackptbd/" target="_blank" rel="noopener noreferrer">
      <FaFacebook
          size={30}
          className="fill-white hover:scale-105  duration-10 mx-2  hover:fill-green-600"
        />
        </a>
        <a href= "https://www.instagram.com/jackpt_bd/" target="_blank" rel="noopener noreferrer">
        <FaInstagram
          size={30}
          className="fill-white hover:scale-105  duration-10 mx-2 hover:fill-green-600"
        />
        </a>
        <a href="https://www.linkedin.com/company/96288207/" target="_blank" rel="noopener noreferrer">
        <FaLinkedin
          size={30}
          className="fill-white hover:scale-105  duration-10 mx-2 hover:fill-green-600"
        />
        </a>
        <a href= "mailto:azmainmorshed03@gmail.com" target="_blank" rel="noopener noreferrer">
        <FaEnvelope
          size={30}
          className="fill-white hover:scale-105 duration-10 mx-2 hover:fill-green-600"
        />
        </a>
      </div>
      <h1 className="bg-black w-full text-sm text-gray-400 mb-0 pb-10 pt-0">
        Developed by Crater Co 2023. All rights reserved.
      </h1>
    </div>
  );
};

export default Footer;
